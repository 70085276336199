<template>
  <div class="flex">
    <div
      v-show="upload"
      class="upload"
    >
      <div>
        <div @click="upload = !upload">
          <r-icon
            class="mr-2 margin-right"
            icon="close"
            size="20"
            fill="rocky"
          />
        </div>
        <div class="parent align-center">
          <div class="container-upload mb-4">
            <h1
              v-if="context.length > 0"
              class="mb-3 fargo--text"
            >
              Просрочено
            </h1>
            <div
              class="align-items-center"
              v-for="item in context"
              :key="keyContext"
            >
              <div class="burrata mb-2">
                № {{item.id}}
              </div>
              <div class="mb-2 sulguni">
                {{item.description}}
              </div>
              <div class="flex mb-3">
                <div class="mr-8 mozzarell flex" v-if="item.workers">
                  <span class="sulguni mr-1">
                   Исполнитель:
                 </span>
                  <div>
                   <span v-for="value in item.workers">
                     {{ value.fio }}
                   </span>
                  </div>
                </div>
                <div class="mr-8 mozzarella">
                  <span class="sulguni mr-1">
                    Дата создания:
                  </span>
                  {{dateFilter(item.created)}}
                </div>
                <div v-if="item.deadline" class="mr-8 mozzarella">
                  <span class="sulguni mr-1">
                   Срок решения:
                  </span>
                  {{item.deadline ? dateFilter(item.deadline) : ''}}
                </div>
              </div>
            </div>
            <h1
              v-if="contextSoled.length > 0"
              class="mb-3 rocky--text"
            >
              Решено
            </h1>
            <div
              v-for="item in contextSoled"
              :key="keyContext"
            >
              <div class="burrata mb-2">
                № {{item.id}}
              </div>
              <div class="mb-2 sulguni">
                {{item.description}}
              </div>
              <div class="flex mb-3">
                <div class="mr-8 mozzarell flex" v-if="item.workers">
                  <span class="sulguni mr-1">
                   Исполнитель:
                 </span>
                 <div>
                   <span v-for="value in item.workers">
                     {{ value.fio }}
                   </span>
                 </div>
                </div>
                <div class="mr-8 mozzarella">
                  <span class="sulguni mr-1">
                    Дата создания:
                  </span>
                  {{dateFilter(item.created)}}
                </div>
                <div v-if="item.deadline" class="mr-8 mozzarella">
                  <span class="sulguni mr-1">
                   Срок решения:
                  </span>
                  {{item.deadline ? dateFilter(item.deadline) : ''}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import vClickOutside from 'v-click-outside';
import PlaceApi from '@/api/PlaceApi';
import problem from "@/components/Problem.vue";

const api = new PlaceApi();
Vue.use(vClickOutside);

export default {
  name: 'Upload',
  components: {
  },
  data() {
    return {
      upload: false,
      context: [],
      contextSoled: [],
      keyContext: 0
    };
  },
  computed: {
  },
  created() {
  },
  methods: {
    dateFilter(e) {
      const date = new Date(e);
      let dd = date.getDate();
      if (dd < 10) dd = `0${dd}`;
      let mm = date.getMonth() + 1;
      if (mm < 10) mm = `0${mm}`;
      const yy = date.getFullYear();
      let h = date.getHours();
      if (h < 10) h = `0${h}`;
      let m = date.getMinutes();
      if (m < 10) m = `0${m}`;
      return `${dd}.${mm}.${yy} ${h}:${m}`;
    },
    async uploadContainer(solid, notClosed) {
      this.contextSoled = [];
      this.context = [];
      if(solid?.length > 0) {
        await api.getAllProblem(solid).then(response => {
          this.contextSoled = response.all;
          this.keyContext++;
        });
      }

      if(notClosed?.length > 0) {
        await api.getAllProblem(notClosed).then(response => {
          this.context = response.all;
          this.keyContext++;
        });
      }
      if(notClosed?.length > 0 || solid?.length > 0) {
        this.upload = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.align-center {
  align-items: center;
  float: left;
  margin-top: 5px;
}

.align-centerEnd {
  align-items: center;
  float: left;
  margin-top: 5px;

}

.upload {
  background-color: white;
  z-index: 100000000;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow-y: scroll;
}

.upload::-webkit-scrollbar-track {
  width: 4px;
  background: #FFFFFF;
}

.upload::-webkit-scrollbar {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: 0.72;
}

.upload::-webkit-scrollbar-thumb {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: 0.72;
}

.parent {
  display: block;
  position: absolute;
  width: 70%;
  height: 400px;
  top: 20%;
  margin-left: 18%;
  margin-top: -150px;
  @media(max-width: 599px) {
    width: 100%;
    margin-left: 0;
    margin-top: 0;
    padding: 0 2.5vw;
  }
}

.container-upload {
  width: 87%;
  height: 300px;
  @media(max-width: 599px) {
    width: 100%
  }
}

.parentEnd {
  display: block;
  position: absolute;
  width: 70%;
  height: 400px;
  top: 50%;
  margin-left: 28%;
  margin-top: -180px;
}

.container-uploadEnd {
  width: 55%;
  height: 300px;
}

.margin-right {
  float: right;
  margin-top: 17px;
  margin-right: 17px;
  z-index: 1000;
  cursor: pointer;
}

.font {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
}

.culture {
  max-width: 100%;
  flex-direction: column-reverse;

  &__cards {
    width: 100%;
  }

  &__map-cont {
    position: relative;
    top: unset;
    height: unset;
    padding: 0;
    margin-left: unset;
    width: 100%;
  }

  &__map {
    height: 160px;
    margin-bottom: 32px;
  }

  &__map--collapsed {
    height: 112px;
  }

  &__map-collapse-btn {
    height: 40px;
    width: 174px;
    border-radius: 8px;
    padding: 10px 16px;
    position: absolute;
    bottom: 10px;
    left: calc(50% - 87px);
    background-color: var(--rir-arrival);
    cursor: pointer;
    display: block;

    :first-child {
      color: var(--rir-godfather);
      border-bottom: 2px dashed #214eb052;
    }
  }

}

.hours {
  display: table;
  background: #FFFFFF;
  box-shadow: 0px 9px 28px rgba(17, 48, 121, 0.18);
  border-radius: 8px;
  margin-top: 60px;
  z-index: 1200;
  position: relative;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  text-align: center;
  height: 160px;
  position: absolute;
  width: 300px;
  margin-top: 0px;

  .hour {
    margin-top: 5px;
    max-height: 150px;
  }

  .minutes {
    margin-top: 5px;
    max-height: 150px;
  }

  .hour::-webkit-scrollbar-track {
    width: 4px;
    background: #FFFFFF;
  }

  .hour::-webkit-scrollbar {
    width: 4px;
    background: #e4edfb;
    border-radius: 2px;
    opacity: 0.72;
  }

  .hour::-webkit-scrollbar-thumb {
    width: 4px;
    background: #e4edfb;
    border-radius: 2px;
    opacity: 0.72;
  }

  .minutes::-webkit-scrollbar-track {
    width: 4px;
    background: #FFFFFF;
  }

  .minutes::-webkit-scrollbar {
    width: 4px;
    background: #e4edfb;
    border-radius: 2px;
    opacity: 0.40;
  }

  .minutes::-webkit-scrollbar-thumb {
    width: 4px;
    background: #e4edfb;
    border-radius: 2px;
    opacity: 0.40;
  }

  .time-hover {
    width: 60px;
    margin: auto;
    margin-top: 6px;
  }

  .time-hover:hover {
    width: 60px;
    background: #e4edfb;
    margin: auto;
    color: #3D75E4;
    border-radius: 5px;
    margin-top: 6px;
  }
}

.clear {
  height: 56px;
  background: #3D75E4;
  opacity: 0.08;
  border-radius: 8px;
}

@media (max-width: 500px) {
  .mobile_column {
    flex-direction: column;

    .video__login.mr-6 {
      margin-bottom: 24px;
      margin-right: 0 !important;
    }
  }
}
.rir-date-picker__time.no_date{
  position: static;
  left: unset;
  top: unset;
}
.rir-date-picker__time .hour>span, .rir-date-picker__time .minutes>span{
  width: auto;
}
::v-deep textarea:focus{
  background: transparent!important;
}
.rir-date-picker__time_wrapper,
.rir-date-picker__time{
  width: 100%;
}


.selected-dates {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  &__container {
    font-size: 12px;
    background-color: var(--rir-alien);
    color: var(--rir-amelie);
    padding: 4px 8px;
    border-radius: 50px;
  }

  &__badge {
    padding: 4px 8px;
    border-radius: 30px;
    background-color: var(--rir-alien);
    color: var(--rir-amelie);
    font-size: 14px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;

    &:hover .selected-dates__clear {
      opacity: 1;
      visibility: visible;
    }
  }

  &__clear {
    position: absolute;
    top: 0;
    right: 0;
    align-self: stretch;
    height: 100%;
    width: 40%;
    background-image: linear-gradient(90deg, transparent, var(--rir-alien) 70%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    cursor: pointer;
  }
}

.grid-time {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
<style>
.RTextarea {
  background-color: #eaebed !important;
}
.RPopover__content {
  z-index: 100000000021 !important;
}
.RDatePicker__input {
  background-color: #eaebed !important;
  border-radius: 8px;
}
</style>
