<template>
  <div>
    <div
      class="w-full float-left"
      :style="widthResize"
    >
      <div class="m-8px p-16px bg-white radius-16 border-arrival">
        <div class="flex justify-between">
          <div
            class="flex m-16px"
          >
            <div class="overflow-ellipsis webkit-box text-h20-24 color-titanic">
              <r-tooltip
                title="Точки сбора ТКО"
                position="top-center"
                :is-arrow="true"
                align-title="start"
                max-width="300px"
              >
                <template #activator>
                  Точки сбора ТКО
                </template>
              </r-tooltip>
            </div>
          </div>
          <div class="ml-16px flex">
            <div class="relative">
              <drop-down-button
                :items="periodList"
                :period="selectedPeriod"
                @select="onChangePeriod"
              />
            </div>
            <r-date-picker
              label=""
              class="cleaning_dateTKO hw-0"
              fill="rocky"
              v-model="period"
              @input="perodFnc"
              :period="true"
            />
            <div class="relative ml-4">
              <additional-menu>
                <ul class="obj-card__menu">
                  <li
                    class="sulguni align-items-center flex pointer"
                    v-if="resize"
                    @click="resizeOff"
                  >
                    <icon-graph graph="resizeOff" />
                    Свернуть
                  </li>
                </ul>
                <ul class="obj-card__menu">
                  <li
                    class="sulguni align-items-center flex pointer"
                    v-if="!resize"
                    @click="resizeOn"
                  >
                    <icon-graph graph="resizeOn" />
                    Развернуть
                  </li>
                </ul>
                <ul
                  class="obj-card__menu mt-4"
                  v-if="graph"
                  @click="graphOff"
                >
                  <li class="sulguni align-items-center flex pointer">
                    <icon-graph graph="graphOff" />
                    Гистограмма
                  </li>
                </ul>
                <ul
                  class="obj-card__menu mt-4"
                  v-if="!graph"
                  @click="graphOn"
                >
                  <li class="sulguni align-items-center flex pointer">
                    <icon-graph graph="graphOn" />
                    График
                  </li>
                </ul>
              </additional-menu>
            </div>
          </div>
        </div>
        <div
          class="mt-20px"
          v-if="notGraph"
        >
          <apexchart
            :key="count"
            class="isSingleItem"
            :type="graph ? 'line' : 'bar'"
            :options="chartOptions"
            :series="series"
            height="223"
          />
        </div>
        <div
          v-else
          class="overflow-hidden relative-mb"
        >
          <div
            class="h-238"
            width="100%"
          >
            <div
              class="apexcharts-canvas apexchartsiiyag009 apexcharts-theme-light hw ml-auto mr-auto"
            >
              <svg
                width="476"
                height="223"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                class="apexcharts-svg transparent"
                transform="translate(0, 0)"
              >
                <g class="apexcharts-annotations" />
                <g class="apexcharts-inner apexcharts-graphical">
                  <defs />
                </g>
                <text
                  font-family="Golos UI, Trebuchet MS, Verdana, sans-serif"
                  x="238"
                  y="129.5"
                  text-anchor="middle"
                  dominant-baseline="auto"
                  font-size="16px"
                  fill="RGBA(4,21,62,0.49)"
                  class="apexcharts-text opacity-72"
                >
                  Нет данных за выбранный период
                </text>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apexchart from 'vue-apexcharts';
import DropDownButton from '@/components/DropDownButton';
import AdditionalMenu from '@/components/AdditionalMenu';
import IconGraph from '@/components/IconGraph';

export default {
  name: 'ProblemTKO',
  components: {
    IconGraph,
    apexchart,
    DropDownButton,
    AdditionalMenu
  },
  props: {
    picker: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      notGraph: false,
      widthResize: 'width: 50%',
      resize: false,
      graph: true,
      count: 0,
      period: null,
      selectedPeriod: 'Месяц',
      periodList: [
        { id: 'today', active: false, value: 'Сегодня' },
        { id: 'thisWeek', active: false, value: 'Неделя' },
        { id: 'thisMonth', active: true, value: 'Месяц' },
        { id: 'thisPeriod', active: false, value: 'Период' }
      ],
      selectedPeriodId: 'thisMonth',
      ser: [{
        name: 'Net Profit',
        data: [190, 19, 190, 19, 190, 19, 190, 19,
          190, 19, 190, 19, 190, 19, 190,
          19, 190, 19, 190, 19, 190, 19,
          190, 19, 190, 19, 190, 19, 190,
          19

        ]
      }, {
        name: 'Net Profit1',
        data: [90, 54, 39, 44, 55, 66, 80, 12,
          43, 19, 32, 54, 66, 23, 22,
          81, 80, 87, 90, 54, 66, 87,
          33, 54, 90, 66, 66, 27, 40,
          45

        ]
      }],
      series: [{
        name: 'Net Profit1',
        data: [90, 54, 39, 44, 55, 66, 80, 12,
          43, 19, 32, 54, 66, 23, 22,
          81, 80, 87, 90, 54, 66, 87,
          33, 54, 90, 66, 66, 27, 40,
          45

        ]
      }],
      chartOptions: {
        chart: {
          id: 'realtime',
          height: 350,
          type: 'line',
          animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: {
              speed: 1000
            }
          },
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          curve: 'smooth',
          width: 2
        },
        title: {
          text: '',
          align: 'center'
        },
        markers: {
          size: 0,
          colors: '#fff',
          strokeWidth: 0,
          strokeColors: ['rgb(141, 201, 94)'],
          hover: {
            size: 0
            // sizeOffset: 1,
          }
        },
        legend: {
          show: false
        },
        // fill: {
        //   colors: [ ({ value, index, w }) => {
        //     return (typeof upLvl === 'number' && typeof downLvl === 'number')
        //       ? (value < upLvl && value > downLvl ? '#81ABEE' : '#F196A5')
        //       : ('#81ABEE')
        //   } ],
        // },
        grid: {
          show: true,
          borderColor: '#E4EDFB',
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        colors: ['rgb(141, 201, 94)'],
        plotOptions: {
          bar: {
            columnWidth: '20%',
            borderRadius: 1
          }
        },
        states: {
          active: {
            filter: {
              type: 'none'
            }
          }
        },
        xaxis: {
          categories: ['1.10', '2.10', '3.10', '4.10', '5.10', '6.10', '7.10', '8.10', '9.10', '10.10',
            '11.10', '12.10', '13.10', '14.10', '15.10', '16.10', '17.10', '18.10',
            '19.10', '20.10', '21.10', '22.10', '23.10', '24.10',
            '25.10', '26.10', '27.10', '28.10', '29.10', '30.10'
          ],
          type: 'category',
          tickPlacement: 'between',
          labels: {
            offsetX: 3,
            rotate: -1,
            showDuplicates: false,
            hideOverlappingLabels: false,
            style: {
              fontSize: '9px',
              fontFamily: 'Golos UI, Trebuchet MS, Verdana, sans-serif',
              fontWeight: 400
            }
          },
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          },
          crosshairs: {
            show: false
          },
          tooltip: {
            enabled: false
          }
        },

        noData: {
          text: 'Нет данных за выбранный период',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: 'RGBA(4,21,62,0.49)',
            fontSize: '16px',
            fontFamily: 'Golos UI, Trebuchet MS, Verdana, sans-serif'
          }
        }
      }
    };
  },
  methods: {
    resizeOff() {
      this.resize = false;
      this.widthResize = 'width: 50%';
      this.count++;
    },
    resizeOn() {
      this.resize = true;
      this.widthResize = 'width: 100%';
      this.count++;
    },
    graphOff() {
      this.graph = false;
    },
    graphOn() {
      this.graph = true;
    },
    perodFnc() {
      if (this.selectedPeriodId == 'thisPeriod') {
        this.selectedPeriod = `${this.period[0]}-${this.period[1] != undefined ? this.period[1] : '...'}`;
        this.count++;
      }
    },
    onChangePeriod(e) {
      this.periodList = this.periodList.map(el => ({ ...el, active: el.value === e.value }));
      this.selectedPeriod = e.value;
      this.selectedPeriodId = e.id;
      if (e.id == 'thisPeriod') {
        const parent = document.querySelectorAll('.cleaning_dateTKO')[0];
        parent.querySelectorAll('.RDatePicker__input')[0].click();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.objects {
  &__filter {
    margin-top: 24px;
    display: flex;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }

    a {
      border-radius: 16px;

      &:hover {
        box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
      }
    }
  }

  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}

.singleItem {
  :global(.apexcharts-xaxis) {
    transform: translate(calc(50% - 18px), 0);
  }
}

.cleaning_dateTKO {
  visibility: hidden;
  ::v-deep .rir-input {
    background: transparent;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding: 0;
    max-height: 0px !important;

    &__body {
      padding: 0;
      flex: none;
      margin-left: 6px;
    }

    &__input {
      height: auto;
    }

    &__label {
      transform: translateY(1px);
      color: #3D75E4 !important;
      position: relative;
      left: unset;
      top: unset;
      font-size: 16px;
    }

    input {
      display: none;
    }

    &__after {
      position: relative;
      margin-left: 0;

      i {
        background-color: var(--rir-rocky) !important;
        opacity: 1;
        display: none !important;
      }

      a:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 110px;
        left: 100%;
        z-index: 9;
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.float-left {
  float: left;
}

.m-20px {
  margin-bottom: 20px;
}

.hw-0 {
  height: 0;
  width: 0;
}

.relative-mb {
  height: 223px;
  position: relative;
  margin-bottom: 52px;
}

.hw-8 {
  width: 8px;
  height: 8px;
}

.h-277 {
  height: 277px;
}

.h-223 {
  height: 223px;
}

.transparent {
  background: transparent;
  margin-top: 26px;
}

.hw {
  width: 476px;
  height: 223px;
}

.w-100 {
  display: table;
  width: 100%;
}
.h-238 {
  min-height: 238px;
}
</style>
