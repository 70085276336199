<template>
  <div>
    <div
      class="w-full float-left"
      :style="widthResize"
    >
      <div
        class="m-8px p-16px bg-white radius-16 border-arrival"
      >
        <div
          class="flex justify-between"
        >
          <div
            class="flex m-16px"
          >
            <div
              class="overflow-ellipsis webkit-box text-h20-24 color-titanic"
            >
              <r-tooltip
                title="Общий показатель эффективности деятельности Главы района"
                position="top-center"
                :is-arrow="true"
                align-title="start"
                max-width="350px"
              >
                <template
                  #activator
                >
                  Общий показатель эффективности деятельности Главы района
                </template>
              </r-tooltip>
            </div>
          </div>
          <div
            class="ml-16px flex"
          >
            <div
              class="relative"
              v-if="false"
            >
              <drop-down-button
                :items="periodList"
                :period="selectedPeriod"
                @select="onChangePeriod"
              />
            </div>
            <r-date-picker
              label=""
              class="cleaning_dateLighting hw-0"
              fill="rocky"
              v-model="period"
              @input="perodFnc"
              :period="true"
            />
            <div
              class="relative ml-4"
            >
              <additional-menu>
                <ul
                  class="obj-card__menu"
                >
                  <li
                    class="sulguni align-items-center flex pointer"
                    v-if="resize"
                    @click="resizeOff"
                  >
                    <icon-graph
                      graph="resizeOff"
                    />
                    Свернуть
                  </li>
                </ul>
                <ul
                  class="obj-card__menu"
                >
                  <li
                    class="sulguni align-items-center flex pointer"
                    v-if="!resize"
                    @click="resizeOn"
                  >
                    <icon-graph
                      graph="resizeOn"
                    />
                    Развернуть
                  </li>
                </ul>
              </additional-menu>
            </div>
          </div>
        </div>
        <div
          class="mt-20px"
          v-if="notGraph"
        >
          <a
            class="inline-flex pointer items-center"
          >
            <div
              class="ml-12px radius-100 flex-none hw-8 blue"
            />
            <div
              class="opacity-72 ml-6px whitespace-nowrap text-c13 color-titanic"
            >
              Фактическое значение за 2021 год
            </div>
          </a>
          <a
            class="inline-flex pointer items-center"
          >
            <div
              class="ml-12px radius-100 flex-none hw-8 red"
            />
            <div
              class="opacity-72 ml-6px whitespace-nowrap text-c13 color-titanic"
            >
              Отклонения от планового значения
            </div>
          </a>
          <apexchart
            :key="count"
            class="isSingleItem"
            :type="graph ? 'line' : 'bar'"
            :options="chartOptions"
            :series="series"
            height="223"
          />
          <div
            class="table"
          >
            <div
              class="ml-6px color-titanic mb-2 opacity-48 caprino"
            />
          </div>
        </div>
        <div
          v-else
          class="overflow-hidden relative-mb"
        >
          <div
            width="100%"
            class="h-238"
          >
            <div
              class="apexcharts-canvas apexchartsiiyag009 apexcharts-theme-light ml-auto mr-auto hw"
            >
              <svg
                width="476"
                height="223"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                class="apexcharts-svg transparent"
                transform="translate(0, 0)"
              >
                <g
                  class="apexcharts-annotations"
                />
                <g
                  class="apexcharts-inner apexcharts-graphical"
                >
                  <defs />
                </g>
                <text
                  font-family="Golos UI, Trebuchet MS, Verdana, sans-serif"
                  x="238"
                  y="129.5"
                  text-anchor="middle"
                  dominant-baseline="auto"
                  font-size="16px"
                  fill="RGBA(4,21,62,0.49)"
                  class="apexcharts-text opacity-72"
                >
                  Нет данных за выбранный период
                </text>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apexchart from 'vue-apexcharts';
import DropDownButton from '@/components/DropDownButton';
import AdditionalMenu from '@/components/AdditionalMenu';
import IconGraph from '@/components/IconGraph';

export default {
  name: 'ProblemLighting',
  components: {
    IconGraph,
    apexchart,
    DropDownButton,
    AdditionalMenu
  },
  props: {
    picker: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      notGraph: true,
      widthResize: 'width: 50%',
      resize: false,
      graph: false,
      count: 0,
      period: null,
      selectedPeriod: 'Месяц',
      periodList: [
        { id: 'today', active: false, value: 'Сегодня' },
        { id: 'thisWeek', active: false, value: 'Неделя' },
        { id: 'thisMonth', active: true, value: 'Месяц' },
        { id: 'thisPeriod', active: false, value: 'Период' }
      ],
      selectedPeriodId: 'thisMonth',
      ser: [{}],
      series: [{
        name: 'Фактическое значение за 2021 год',
        data: [0.83, 0.89, 0.88, 0.82, 0.75, 0.65, 0.92, 0.9]
      }, {
        name: 'Отклонения от планового значения',
        data: [0.17, 0.11, 0.12, 0.18, 0.25, 0.35, 0.08, 0.1]
      }],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: true
          }
        },
        colors: ['#3D75E4', '#A52A2A'],
        dataLabels: {
          style: {
            fontSize: '11px',
            fontFamily: 'Golos UI, Trebuchet MS, Verdana, sans-serif',
            fontWeight: 400
          },
          enabled: true,
          position: 'left',
          textAnchor: 'start',
          formatter(val, opt) {
            if (opt.seriesIndex == 0) {
              if (opt.dataPointIndex == 0) {
                return 'Итоговый коэффициент';
              }
              if (opt.dataPointIndex == 1) {
                return 'Уровень доверия в обществе';
              }
              if (opt.dataPointIndex == 2) {
                return 'Эффективность взаимодействия с населением';
              }
              if (opt.dataPointIndex == 3) {
                return 'Эффективность управления социальной сферой';
              }
              if (opt.dataPointIndex == 4) {
                return 'Качество городской среды';
              }
              if (opt.dataPointIndex == 5) {
                return 'Создание условий для развития бизнеса';
              }
              if (opt.dataPointIndex == 6) {
                return 'Качество управления муниципальными финансами';
              }
              if (opt.dataPointIndex == 7) {
                return 'Уровень исполнительской дисциплины';
              }
              return '';
            }
            return '';
          }
        },
        stroke: {
          show: false,
          curve: 'smooth',
          width: 2
        },
        title: {
          text: '',
          align: 'center'
        },
        responsive: [{
          breakpoint: 380,
          options: {
            legend: {
              position: 'top',
              offsetX: 0,
              offsetY: 0
            }
          }
        }],
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'bottom'
            }
          }
        },
        grid: {
          show: true,
          borderColor: '#E4EDFB',
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        xaxis: {
          align: 'right',
          categories: ['', '', '', '', '', '', '', ''],
          type: 'category',
          tickPlacement: 'between',
          labels: {
            offsetX: 4,
            rotate: -1,
            showDuplicates: false,
            hideOverlappingLabels: false,
            style: {
              fontSize: '9px',
              fontFamily: 'Golos UI, Trebuchet MS, Verdana, sans-serif',
              fontWeight: 400
            }
          },
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          },
          crosshairs: {
            show: false
          },
          tooltip: {
            enabled: false
          }
        },
        legend: {
          show: false
        },
        fill: {
          opacity: 1
        },
        yaxis: {
          min: 0,
          max: 1,
          tickAmount: 5,
          labels: {
            show: true,
            formatter(val) {
              return val;
            },
            style: {
              fontSize: '9px',
              fontFamily: 'Golos UI, Trebuchet MS, Verdana, sans-serif',
              fontWeight: 400
            }
          }
        }
      }
    };
  },
  methods: {
    resizeOff() {
      this.resize = false;
      this.widthResize = 'width: 50%';
      this.count++;
    },
    resizeOn() {
      this.resize = true;
      this.widthResize = 'width: 100%';
      this.count++;
    },
    graphOff() {
      this.graph = false;
    },
    graphOn() {
      this.graph = true;
    },
    perodFnc() {
      if (this.selectedPeriodId == 'thisPeriod') {
        this.selectedPeriod = `${this.period[0]}-${this.period[1] != undefined ? this.period[1] : '...'}`;
        this.count++;
      }
    },
    onChangePeriod(e) {
      this.periodList = this.periodList.map(el => ({ ...el, active: el.value === e.value }));
      this.selectedPeriod = e.value;
      this.selectedPeriodId = e.id;
      if (e.id == 'thisPeriod') {
        const parent = document.querySelectorAll('.cleaning_dateLighting')[0];
        parent.querySelectorAll('.RDatePicker__input')[0].click();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.objects {
  &__filter {
    margin-top: 24px;
    display: flex;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }

    a {
      border-radius: 16px;

      &:hover {
        box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
      }
    }
  }

  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}

.singleItem {
  :global(.apexcharts-xaxis) {
    transform: translate(calc(50% - 18px), 0);
  }
}

.cleaning_dateLighting {
  visibility: hidden;

  ::v-deep .rir-input {
    background: transparent;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding: 0;
    max-height: 0px !important;

    &__body {
      padding: 0;
      flex: none;
      margin-left: 6px;
    }

    &__input {
      height: auto;
    }

    &__label {
      transform: translateY(1px);
      color: #3D75E4 !important;
      position: relative;
      left: unset;
      top: unset;
      font-size: 16px;
    }

    input {
      display: none;
    }

    &__after {
      position: relative;
      margin-left: 0;

      i {
        background-color: var(--rir-rocky) !important;
        opacity: 1;
        display: none !important;
      }

      a:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 110px;
        left: 100%;
        z-index: 9;
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.float-left {
  float: left;
}

.m-20px {
  margin-bottom: 20px;
}

.hw-0 {
  height: 0;
  width: 0;
}

.hw-8 {
  width: 8px;
  height: 8px;
}

.blue {
  background-color: #3D75E4;
}

.red {
  background-color: #A52A2A;
}

.relative-mb {
  height: 223px;
  position: relative;
  margin-bottom: 52px;
}

.h-238 {
  min-height: 238px;
}

.transparent {
  background: transparent;
  margin-top: 26px;
}

.hw {
  width: 476px;
  height: 223px;
}

.table {
  display: table;
}
</style>
