import Vue from 'vue';
import VueRouter from 'vue-router';
import Admin from '../views/Admin.vue';
import ObjectList from '../views/ObjectList.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/Admin.vue')
    component: Admin,
    children: [
      {
        name: 'index',
        path: '',
        component: ObjectList
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
});

export default router;
