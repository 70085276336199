<template>
  <div
    class="objects"
  >
    <h1
      class="mb-6 width"
    >
      {{ moduleTitle }}
    </h1>
    <problem
      @my-event="dataFunc"
      v-if="PROBLEMS"
    />
    <problem-report v-if="PROBLEMS" />
    <problem-transport v-if="PUBLIC_TRANSPORT" />
    <problem-city v-if="CITY" />
    <problem-market v-if="MARKET" />
    <problem-service v-if="SERVICE_TRANSPORT" />
    <problem-resource v-if="IOT" />
    <problem-user v-if="SKYD" />
    <problem-s-c-u-d v-if="SKYD" />
    <problem-time v-if="SKYD" />
    <problem-lighting v-if="LIGHT" />
    <problem-t-k-o v-if="GARBAGE_CTRL" />
    <problem-cool v-if="HEAT" />
  </div>
</template>

<script>
import Problem from '@/components/Problem';
import ProblemTransport from '@/components/ProblemTransport';
import ProblemReport from '@/components/ProblemReport';
import ProblemLighting from '@/components/ProblemLighting';
import ProblemTKO from '@/components/ProblemTKO';
import ProblemResource from '@/components/ProblemResource';
import ProblemService from '@/components/ProblemService';
import ProblemMarket from '@/components/ProblemMarket';
import ProblemCool from '@/components/ProblemCool';
import ProblemUser from '@/components/ProblemUser';
import ProblemSCUD from '@/components/ProblemSCUD';
import ProblemTime from '@/components/ProblemTime';
import ProblemCity from '@/components/ProblemCity';

import PlaceApi from '@/api/PlaceApi';

const api = new PlaceApi();

export default {
  name: 'ObjectList',
  components: {
    ProblemTime,
    ProblemSCUD,
    ProblemUser,
    ProblemCool,
    ProblemMarket,
    ProblemService,
    ProblemResource,
    ProblemTKO,
    ProblemLighting,
    Problem,
    ProblemTransport,
    ProblemReport,
    ProblemCity
  },
  data() {
    return {
      PROBLEMS: false,
      GARBAGE_CTRL: false,
      PUBLIC_TRANSPORT: false,
      HEAT: false,
      SKYD: false,
      SERVICE_TRANSPORT: false,
      IOT: false,
      TRANSPORT: false,
      LIGHT: false,
      MARKET: false,
      CITY: false
    };
  },
  computed: {
    moduleTitle() {
      return window.moduleTitle || 'Дашборд';
    }
  },
  async mounted() {
    const _this = this;
    await api.getAccess().then(response => {
      response.accesses.forEach((value, index) => {
        // Городские проблемы
        if (value.module.tag == 'PROBLEMS') {
          _this.PROBLEMS = value.module.stats;
        }
        // Контроль вывоза тко
        if (value.module.tag == 'GARBAGE_CTRL') {
          _this.GARBAGE_CTRL = value.module.stats;
        }
        // Общественный транспорт
        if (value.module.tag == 'PUBLIC_TRANSPORT') {
          _this.PUBLIC_TRANSPORT = value.module.stats;
        }
        /*
        // Отопление
        if (value.module.tag == 'HEAT') {
          _this.HEAT = value.module.stats;
        }
        */
        // СКУД
        if (value.module.tag == 'SKYD') {
          _this.SKYD = value.module.stats;
        }
        // Служебный транспорт
        if (value.module.tag == 'SERVICE_TRANSPORT') {
          _this.SERVICE_TRANSPORT = value.module.stats;
        }
        // Счётчики
        if (value.module.tag == 'IOT') {
          _this.IOT = value.module.stats;
        }
        // Управление освещением
        if (value.module.tag == 'LIGHT') {
          _this.LIGHT = value.module.stats;
        }

        if (value.module.tag == 'MP_EDU' || value.module.tag == 'MP_SPORT') {
          _this.MARKET = value.module.stats;
        }
        _this.CITY = false;
      });
    });
  },
  methods: {
    dataFunc() {
      this.data = [];
    }
  }
};
</script>

<style lang="scss" scoped>
.objects {
  &__filter {
    margin-top: 24px;
    display: flex;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }

    a {
      border-radius: 16px;

      &:hover {
        box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
      }
    }
  }

  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}

.singleItem {
  :global(.apexcharts-xaxis) {
    transform: translate(calc(50% - 18px), 0);
  }
}

.cleaning_date {
  visibility: hidden;
  ::v-deep .rir-input {
    background: transparent;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding: 0;
    max-height: 0px !important;
    &__body {
      padding: 0;
      flex: none;
      margin-left: 6px;
    }

    &__input {
      height: auto;
    }

    &__label {
      transform: translateY(1px);
      color: #3D75E4 !important;
      position: relative;
      left: unset;
      top: unset;
      font-size: 16px;
    }

    input {
      display: none;
    }

    &__after {
      position: relative;
      margin-left: 0;
      i{
        background-color: var(--rir-rocky) !important;
        opacity: 1;
        display: none !important;
      }
      a:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 110px;
        left: 100%;
        z-index: 9;
      }
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.width {
  width: 100%;
}
</style>
<style>
* {
  color: inherit;
}

.apexcharts-text tspan {
  font-size: 10px !important;
}

.RButtonAction {
  background-color: transparent !important;
}
</style>
