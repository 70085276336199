<template>
  <div>
    <div
      class="w-full float-left"
      :style="widthResize"
    >
      <div
        class="m-8px p-16px bg-white radius-16 border-arrival"
      >
        <div
          class="flex m-16px"
        >
          <div
            class="overflow-ellipsis webkit-box text-h20-24 color-titanic"
          >
            <r-tooltip
              title="Отчет &quot;Сообщения жителей&quot;"
              position="top-center"
              :is-arrow="true"
              align-title="start"
              max-width="300px"
            >
              <template
                #activator
              >
                Отчет "Сообщения жителей"
              </template>
            </r-tooltip>
          </div>
        </div>
        <r-select
          :key="countSearch"
          label="Категория"
          :value="types"
          :items="sortTypes"
          v-model="types"
          @change="sortFunc"
        />
        <r-select
          class="mt-5"
          :value="optionsTypes"
          v-model="optionsTypes"
          :items="options"
          @change="sortOptionFunc"
          label="Статус обращений"
        />
        <div
          class="flex items-center mt-24px5 mt-5"
        >
          <div
            class="flex-1 relative"
          >
            <r-date-picker
              click-close
              label="Дата"
              v-model="date"
            />
          </div>
          <div
            class="mx-8px bg-rocky hw-81"
          />
          <div
            class="flex-1 relative"
          >
            <r-date-picker
              click-close
              label="Дата"
              v-model="dateEnd"
            />
          </div>
        </div>
        <div
          class="flex mt-4"
        >
          <div
            class="ml-auto mr-auto flex"
          >
            <r-radio-button
              title="Последние 20"
              :value="radio"
              class="mr-8"
              @input="changeVal"
            />
            <r-radio-button
              title="Все"
              :value="!radio"
              @input="changeVal"
            />
          </div>
        </div>
        <r-button
          class="flex-1 mt-10"
          color="secondary"
          @click="Save"
          icon="load"
          width="wide"
          title="Скачать отчет"
        />
      </div>
    </div>
  </div>
</template>

<script>
import apexchart from 'vue-apexcharts';
import IconGraph from '@/components/IconGraph';
import DropDownButton from '@/components/DropDownButton';
import AdditionalMenu from '@/components/AdditionalMenu';
import DropdownSelect from '@/components/DropdownSelect';

import PlaceApi from '@/api/PlaceApi';
import rir from "@/plugins/RirLib";

const api = new PlaceApi();
export default {
  name: 'ProblemReport',
  components: {
    IconGraph,
    apexchart,
    DropDownButton,
    AdditionalMenu,
    DropdownSelect
  },
  props: {
    picker: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      radio: true,
      countSearch: 0,
      widthResize: 'width: 50%',
      resize: true,
      graph: true,
      count: 0,
      period: null,
      sortTypes: [],
      typesId: 1,
      options: [
        {
          id: 'all',
          title: 'Все'
        },
        {
          id: 'new',
          title: 'Новые'
        },
        {
          id: 'assigned',
          title: 'Назначено'
        },
        {
          id: 'inWork',
          title: 'В работе'
        },
        {
          id: 'plan',
          title: 'План'
        },
        {
          id: 'closed',
          title: 'Решено'
        },
        {
          id: 'late',
          title: 'Просрочено'
        },
        {
          id: 'deleted',
          title: 'Отклонено'
        },
        {
          id: 'controlled',
          title: 'На контроле'
        }
      ],
      optionsTypes: 'all',
      types: 1,
      optionsTypesId: 'all',
      date: null,
      dateEnd: null
    };
  },
  mounted() {
    const _this = this;
    api.getTypes().then(response => {
      _this.sortTypes = response.all.map(el => ({
        id: el.id,
        title: el.typeFull,
        active: false,
        icon: el.icon ? el.icon : null
      }));
      _this.sortTypes.unshift({
        id: 1,
        active: true,
        title: 'Все проблемы',
        icon: null
      });
      _this.types = 1;
      _this.countSearch++;
    });
  },
  methods: {
    changeVal() {
      this.radio = !this.radio;
    },
    sortOptionFunc(e) {
      this.optionsTypesId = e;
    },
    sortFunc(e) {
      this.typesId = e;
    },
    Save() {
      const _this = this;
      const arr = {};
      const arrOne = {};
      let filterTypes = null;
      let dateFrom = null;
      let dateTo = null;
      if (_this.date) {
        dateFrom = _this.date;
      }
      if (_this.dateEnd) {
        dateTo = _this.dateEnd;
      }
      _this.options.forEach((value, index) => {
        if (value.id != 'all') {
          arr[value.id] = 1;
        }
        if (value.id == _this.optionsTypesId) {
          arrOne[value.id] = 1;
        }
      });

      if (_this.typesId != 1) {
        filterTypes = [_this.typesId];
      }
      const json = {
        dateFrom,
        dateTo,
        reportType: 3,
        filterTypes,
        extraOptions: { maxPage: _this.radio == true ? 0 : -1 },
        filterStatuses: (_this.optionsTypesId != 'all' ? arrOne : arr)
      };
      api.getFile(json).then(response => {
        if (response?.error) {
          rir.framework.notification.send({
            title: response?.error || 'Ошибка!',
            seconds: 5,
            hiddenClose: false,
            iconOptions: {
              icon: 'warning',
              fill: 'fargo'
            }
          });
        }
        if(response?.url) {
          window.location.href = response.url;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.objects {
  &__filter {
    margin-top: 24px;
    display: flex;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }

    a {
      border-radius: 16px;

      &:hover {
        box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
      }
    }
  }

  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}

.singleItem {
  :global(.apexcharts-xaxis) {
    transform: translate(calc(50% - 18px), 0);
  }
}

.cleaning_date {
  visibility: hidden;

  ::v-deep .rir-input {
    background: transparent;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding: 0;
    max-height: 0px !important;

    &__body {
      padding: 0;
      flex: none;
      margin-left: 6px;
    }

    &__input {
      height: auto;
    }

    &__label {
      transform: translateY(1px);
      color: #3D75E4 !important;
      position: relative;
      left: unset;
      top: unset;
      font-size: 16px;
    }

    input {
      display: none;
    }

    &__after {
      position: relative;
      margin-left: 0;

      i {
        background-color: var(--rir-rocky) !important;
        opacity: 1;
        display: none !important;
      }

      a:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 110px;
        left: 100%;
        z-index: 9;
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.float-left {
  float: left;
}

.hw-81 {
  width: 8px;
  height: 1px;
  border-radius: 1px;
}
</style>
