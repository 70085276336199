<template>
  <div>
    <div
      class="w-full float-left"
      :style="widthResize"
    >
      <div class="m-8px p-16px bg-white radius-16 border-arrival">
        <div class="flex justify-between">
          <div
            class="flex m-16px"
          >
            <div class="overflow-ellipsis webkit-box text-h20-24 color-titanic">
              <r-tooltip
                title="Время пребывания"
                position="top-center"
                :is-arrow="true"
                align-title="start"
                max-width="300px"
              >
                <template #activator>
                  Время пребывания
                </template>
              </r-tooltip>
            </div>
          </div>
          <div class="ml-16px flex">
            <div class="relative">
              <drop-down-button
                :items="periodList"
                :period="selectedPeriod"
                @select="onChangePeriod"
              />
            </div>
            <r-date-picker
              label=""
              class="cleaning_dateTime hw-0"
              fill="rocky"
              v-model="period"
              @input="perodFnc"
              :period="true"
            />
            <div class="relative ml-4">
              <additional-menu>
                <ul class="obj-card__menu">
                  <li
                    class="sulguni align-items-center flex pointer"
                    v-if="resize"
                    @click="resizeOff"
                  >
                    <icon-graph graph="resizeOff" />
                    Свернуть
                  </li>
                </ul>
                <ul class="obj-card__menu">
                  <li
                    class="sulguni align-items-center flex pointer"
                    v-if="!resize"
                    @click="resizeOn"
                  >
                    <icon-graph graph="resizeOn" />
                    Развернуть
                  </li>
                </ul>
                <ul
                  class="obj-card__menu mt-4"
                  v-if="graph"
                  @click="graphOff"
                >
                  <li class="sulguni align-items-center flex pointer">
                    <icon-graph graph="graphOff" />
                    Гистограмма
                  </li>
                </ul>
                <ul
                  class="obj-card__menu mt-4"
                  v-if="!graph"
                  @click="graphOn"
                >
                  <li class="sulguni align-items-center flex pointer">
                    <icon-graph graph="graphOn" />
                    График
                  </li>
                </ul>
              </additional-menu>
            </div>
          </div>
        </div>
        <div
          class="rir-drag-upload-file__uploading h-277"
          v-if="uploadGraph"
        >
          <loading-content class="h-223" />
        </div>
        <div v-if="!uploadGraph">
          <div
            class="mt-20px"
            v-if="notGraph"
          >
            <div
              class="mt-20px"
              :key="count"
            >
              <apexchart
                class="isSingleItem"
                :type="graph ? 'line' : 'bar'"
                :options="chartOptions"
                :series="series"
                height="223"
              />
            </div>
          </div>
          <div
            v-else
            class="overflow-hidden relative-mb"
          >
            <div
              class="h-238"
              width="100%"
            >
              <div
                class="apexcharts-canvas apexchartsiiyag009 apexcharts-theme-light ml-auto mr-auto hw"
              >
                <svg
                  width="476"
                  height="223"
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  class="apexcharts-svg transparent"
                  transform="translate(0, 0)"
                >
                  <g class="apexcharts-annotations" />
                  <g class="apexcharts-inner apexcharts-graphical">
                    <defs />
                  </g>
                  <text
                    font-family="Golos UI, Trebuchet MS, Verdana, sans-serif"
                    x="238"
                    y="129.5"
                    text-anchor="middle"
                    dominant-baseline="auto"
                    font-size="16px"
                    fill="RGBA(4,21,62,0.49)"
                    class="apexcharts-text opacity-72"
                  >
                    {{ text }}
                  </text>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apexchart from 'vue-apexcharts';
import IconGraph from '@/components/IconGraph';
import DropDownButton from '@/components/DropDownButton';
import AdditionalMenu from '@/components/AdditionalMenu';
import PlaceApi from '@/api/PlaceApi';

const api = new PlaceApi();

export default {
  name: 'ProblemUser',
  components: {
    IconGraph,
    apexchart,
    DropDownButton,
    AdditionalMenu
  },
  props: {
    picker: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      uploadGraph: true,
      text: '',
      dataCheck: true,
      dataEndCheck: true,
      days: [],
      dataRec: {},
      dataEndRec: {},
      periodCheck: [],
      data: [],
      dataEnd: [],
      widthResize: 'width: 50%',
      resize: false,
      graph: true,
      count: 0,
      countDays: 0,
      notGraph: false,
      period: null,
      selectedPeriod: 'Месяц',
      periodList: [
        { id: 'today', active: false, value: 'Сегодня' },
        { id: 'thisWeek', active: false, value: 'Неделя' },
        { id: 'thisMonth', active: true, value: 'Месяц' },
        { id: 'thisPeriod', active: false, value: 'Период' }
      ],
      selectedPeriodId: 'thisMonth',
      series: [],
      chartOptions: {}
    };
  },
  computed: {
    notData() {
      this.notGraph = false;
      for (let i = 0; i < this.series.length; i++) {
        if (this.series[i].data.length) {
          this.notGraph = true;
        }
      }
      this.text = 'Нет данных за выбранный период';
    },
    dataFunc() {
      this.series = [{
        name: '',
        data: this.dataCheck ? this.data : []
      }];
      this.notData;
      this.count++;
    },
    dataTimeFunc() {
      this.series = [{
        name: 'Срабатывание',
        data: this.dataCheck ? this.data : []
      }];
      this.uploadGraph = false;
      this.notData;
      this.chartOptions = {
        legend: {
          show: false
        },
        chart: {
          width: '400px',
          toolbar: {
            show: false
          },
          fontFamily: 'Golos UI, Trebuchet MS, Verdana, sans-serif',
          zoom: {
            enabled: false
          }
        },
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            const value = series[seriesIndex][dataPointIndex];
            const [h, m] = String(value).split('.');
            const hourText = Number(h) ? `${h} ч.` : '';
            const minText = Number(m) ? `${m} мин.` : '';

            const text = `${hourText} ${minText}`;
            return `
          <div class="countersGraphTooltip">${text}</div>
          <div class="countersGraphTooltipTail"></div>
        `;
          }
        },
        // fill: {
        //   colors: [ ({ value, index, w }) => {
        //     return (typeof upLvl === 'number' && typeof downLvl === 'number')
        //       ? (value < upLvl && value > downLvl ? '#81ABEE' : '#F196A5')
        //       : ('#81ABEE')
        //   } ],
        // },
        grid: {
          show: true,
          borderColor: '#E4EDFB',
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        colors: ['rgb(141, 201, 94)', 'rgb(187, 154, 244)'],
        plotOptions: {
          bar: {
            columnWidth: '40%',
            borderRadius: 1
          }
        },
        dataLabels: {
          enabled: false
        },
        states: {
          active: {
            filter: {
              type: 'none'
            }
          }
        },
        xaxis: {
          showForNullSeries: true,
          categories: this.days,
          type: 'category',
          tickPlacement: 'between',
          labels: {
            offsetX: 3,
            rotate: -40,
            rotateAlways: true,
            showDuplicates: false,
            hideOverlappingLabels: false,
            style: {
              fontSize: '9px',
              fontFamily: 'Golos UI, Trebuchet MS, Verdana, sans-serif',
              fontWeight: 400
            }
          },
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          },
          crosshairs: {
            show: false
          },
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          show: true,
          showForNullSeries: true,
          labels: {
            show: true,
            style: {
              fontSize: '9px',
              fontFamily: 'Golos UI, Trebuchet MS, Verdana, sans-serif',
              fontWeight: 400
            }
          }
        },
        noData: {
          text: 'Нет данных за выбранный период',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: 'RGBA(4,21,62,0.49)',
            fontSize: '16px',
            fontFamily: 'Golos UI, Trebuchet MS, Verdana, sans-serif'
          }
        },
        markers: {
          size: 4,
          colors: '#fff',
          strokeWidth: 2,
          strokeColors: ['rgb(141, 201, 94)', 'rgb(187, 154, 244)'],
          hover: {
            size: 4
            // sizeOffset: 1,
          }
        },
        stroke: {
          show: true,
          // curve: 'smooth',
          width: 2
        }
      };
    }
  },
  mounted() {
    const _this = this;
    let d = new Date();
    let lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    d = this.formatDate(d);
    lastMonth = this.formatDate(new Date(lastMonth));
    api.geеSCUD(lastMonth, d).then(response => {
      _this.dataRec = response;

      _this.days = _this.getAll(lastMonth, d);
      _this.dataTimeFunc;
      _this.count++;
    });
  },
  methods: {
    formatDate(date) {
      let dd = date.getDate();
      if (dd < 10) dd = `0${dd}`;

      let mm = date.getMonth() + 1;
      if (mm < 10) mm = `0${mm}`;

      const yy = date.getFullYear();

      return `${yy}-${mm}-${dd}`;
    },
    getAll(begin, end) {
      if (!begin || !end) {
        return false;
      }
      const _this = this;
      const ab = begin.split('-');
      const ae = end.split('-');
      const db = new Date();
      db.setUTCFullYear(ab[0], ab[1] - 1, ab[2]);
      const de = new Date();
      de.setUTCFullYear(ae[0], ae[1] - 1, ae[2]);
      const unixDb = db.getTime();
      const unixDe = de.getTime();
      const arr = [];
      let count = 0;
      for (let k = unixDb; k <= unixDe;) {
        if (this.dataRec.length > 0 && this.dataRec[count]) {
          let d = this.dataRec[count].day;
          d = this.formatDate(new Date(d));

          let dateBool = false;
          for (let i = 0; i < this.dataRec.length; i++) {
            if (this.formatDate(new Date(this.dataRec[i].day)) == this.formatDate(new Date(k))) {
              dateBool = true;
              break;
            }
          }

          if (this.dataRec && dateBool) {
            let item = 0;
            if (this.dataRec[count]?.averageTimeInside) {
              item = this.dataRec[count].averageTimeInside.replace(/[:]/g, '.');
            }
            _this.data.push(item);
          } else if (_this.dataRec.length > 0) {
            _this.data.push(0);
          }
        } else if (_this.dataRec.length > 0) {
          _this.data.push(0);
        }
        count++;
        arr.push(`${new Date(k).getDate()}.${(parseInt(new Date(k).getMonth()) + 1) <= 9 ? 0 : ''}${parseInt(new Date(k).getMonth()) + 1}`);
        k += 24 * 60 * 60 * 1000;
      }
      this.count++;
      return arr;
    },
    dataEndFunc(item, type) {
      if (type == 'end') {
        this.dataEndCheck = item;
      } else {
        this.dataCheck = item;
      }
      this.dataFunc;
      this.count++;
    },
    resizeOff() {
      this.resize = false;
      this.widthResize = 'width: 50%';
      this.count++;
    },
    resizeOn() {
      this.resize = true;
      this.widthResize = 'width: 100%';
      this.count++;
    },
    graphOff() {
      this.graph = false;
      this.count++;
    },
    graphOn() {
      this.graph = true;
      this.count++;
    },
    perodFnc(e) {
      const _this = this;
      if (this.selectedPeriodId == 'thisPeriod') {
        this.selectedPeriod = `${this.period[0].replace(/[-]/g, '.')}-${this.period[1] != undefined ? this.period[1].replace(/[-]/g, '.') : '...'}`;
        this.dataEnd = [];
        this.data = [];
        api.geеSCUD(this.period[0], this.period[1]).then(response => {
          _this.dataRec = response;

          _this.days = _this.getAll(_this.period[0], _this.period[1]);
          _this.dataTimeFunc;
          _this.count++;
        });
      }
    },
    onChangePeriod(e) {
      const _this = this;
      this.periodList = this.periodList.map(el => ({ ...el, active: el.value === e.value }));
      this.selectedPeriod = e.value;
      this.selectedPeriodId = e.id;
      if (e.id == 'today') {
        this.dataEnd = [];
        this.data = [];
        let d = new Date();
        d = this.formatDate(d);
        api.geеSCUD(d, d).then(response => {
          _this.dataRec = response;

          _this.days = _this.getAll(d, d);
          _this.dataTimeFunc;
          _this.count++;
        });
      }
      if (e.id == 'thisWeek') {
        this.dataEnd = [];
        this.data = [];
        let d = new Date();
        let lastWeek = new Date();
        lastWeek = new Date().getTime() - 604800000;
        d = this.formatDate(d);
        lastWeek = this.formatDate(new Date(lastWeek));
        api.geеSCUD(lastWeek, d).then(response => {
          _this.dataRec = response;

          _this.days = _this.getAll(lastWeek, d);
          _this.dataTimeFunc;
          _this.count++;
        });
      }
      if (e.id == 'thisMonth') {
        this.dataEnd = [];
        this.data = [];
        let d = new Date();
        let lastMonth = new Date();
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        d = this.formatDate(d);
        lastMonth = this.formatDate(new Date(lastMonth));
        api.geеSCUD(lastMonth, d).then(response => {
          _this.dataRec = response;
          _this.days = _this.getAll(lastMonth, d);
          _this.dataTimeFunc;
          _this.count++;
        });
      }
      if (e.id == 'thisPeriod') {
        const parent = document.querySelectorAll('.cleaning_dateTime')[0];
        parent.querySelectorAll('.RDatePicker__input')[0].click();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.objects {
  &__filter {
    margin-top: 24px;
    display: flex;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }

    a {
      border-radius: 16px;

      &:hover {
        box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
      }
    }
  }

  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}

.singleItem {
  :global(.apexcharts-xaxis) {
    transform: translate(calc(50% - 18px), 0);
  }
}

.cleaning_dateTime {
  visibility: hidden;

  ::v-deep .rir-input {
    background: transparent;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding: 0;
    max-height: 0px !important;

    &__body {
      padding: 0;
      flex: none;
      margin-left: 6px;
    }

    &__input {
      height: auto;
    }

    &__label {
      transform: translateY(1px);
      color: #3D75E4 !important;
      position: relative;
      left: unset;
      top: unset;
      font-size: 16px;
    }

    input {
      display: none;
    }

    &__after {
      position: relative;
      margin-left: 0;

      i {
        background-color: var(--rir-rocky) !important;
        opacity: 1;
        display: none !important;
      }

      a:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 110px;
        left: 100%;
        z-index: 9;
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.float-left {
  float: left;
}

.m-20px {
  margin-bottom: 20px;
}

.hw-0 {
  height: 0;
  width: 0;
}

.relative-mb {
  height: 223px;
  position: relative;
  margin-bottom: 52px;
}

.hw-8 {
  width: 8px;
  height: 8px;
}

.h-277 {
  height: 277px;
}

.h-223 {
  height: 223px;
}

.transparent {
  background: transparent;
  margin-top: 26px;
}

.hw {
  width: 476px;
  height: 223px;
}

.w-100 {
  display: table;
  width: 100%;
}
.h-238 {
  min-height: 238px;
}
</style>
